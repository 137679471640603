import { Dialog, Transition } from '@headlessui/react'
import { DateTime } from 'luxon';
import React, { Fragment } from 'react';
import { BookingLibrary } from '../models/booking.model';

interface BookingModalComponentProps {
  slot: BookingLibrary.AircraftBookingSlot;
  onClose: () => void;
  onClickBookButton: (aircraftRegistration: string, slotBegin: Date, slotEnd: Date) => Promise<void>;
}

interface BookingModalComponentState {
  bookingStart: string;
  bookingEnd: string;
}

export default class BookingModalComponent extends React.Component<BookingModalComponentProps, BookingModalComponentState> {
  constructor(props: BookingModalComponentProps) {
    super(props);

    const slotBegin = DateTime.fromFormat(this.props.slot.begins, 'yyyy-MM-dd HH:mm');
    const slotEnd = DateTime.fromFormat(this.props.slot.ends, 'yyyy-MM-dd HH:mm');
    this.state = {
      bookingStart: slotBegin.set({
        minute: 0,
        second: 0,
        millisecond: 0
      }).toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS"),
      bookingEnd: slotEnd.set({
        minute: 0,
        second: 0,
        millisecond: 0
      }).toFormat("yyyy-MM-dd'T'HH:mm:ss.SSS")
    };
  }

  private handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      [e.target.name]: e.target.value
    } as any);
  }

  render(): JSX.Element {
    return (<Transition appear show={true} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => this.props.onClose() }
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-40"
            leave="ease-in duration-200"
            leaveFrom="opacity-40"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Booking options
              </Dialog.Title>
              <p className="text-md font-medium leading-6 text-gray-900">
                Booking Start
              </p>
              <input
                  type="datetime-local"
                  max={this.state.bookingEnd}
                  name="bookingStart"
                  step={3600}
                  defaultValue={this.state.bookingStart}
                  onChange={e => this.handleChange(e)}
                />
              <p className="text-md font-medium leading-6 text-gray-900">
                Booking End
              </p>
              <input
                  type="datetime-local"
                  min={this.state.bookingStart}
                  name="bookingEnd"
                  step={3600}
                  defaultValue={this.state.bookingEnd}
                  onChange={e => this.handleChange(e)}
                />

              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={() =>
                    this.props.onClickBookButton(
                      this.props.slot.aircraft,
                      DateTime.fromFormat(this.state.bookingStart, "yyyy-MM-dd'T'HH:mm:ss.SSS").toJSDate(),
                      DateTime.fromFormat(this.state.bookingEnd, "yyyy-MM-dd'T'HH:mm:ss.SSS").toJSDate()
                    )
                      .then(this.props.onClose)
                  }
                >
                  Book now
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>);
  }
}
