import React from 'react';
import { RouteComponentProps } from 'react-router-dom'
import MemberBookingApiService from '../api/booking/member-booking-api.service';
import AuthorisedPageContainer from '../components/authorised-page-container.component'
import { BookingLibrary } from '../models/booking.model';
import { AuthService } from '../services/auth.service';
import { classNames } from '../services/classnames.utils';
interface MyAccountPageProps extends RouteComponentProps<{}> {
  pageTitle: string;
}

interface MyAccountPageState {
  tabs: {name: string, isCurrent: boolean}[];
  accountInfo: {name: string, email: string, phone: string, roles: string[]};
  bookings: {[key: string]: BookingLibrary.AircraftBookingSlot};
}

export default class MyAccountPage extends React.Component<MyAccountPageProps, MyAccountPageState> {
  private memberBookingApiService: MemberBookingApiService;

  constructor(props: MyAccountPageProps) {
    super(props);
    this.memberBookingApiService = new MemberBookingApiService();

    this.state = {
      tabs: [
        { name: 'Account', isCurrent: true },
        { name: 'Bookings', isCurrent: false },
        { name: 'Currency', isCurrent: false },
      ],
      accountInfo: {
        name: '',
        email: '',
        phone: '',
        roles: []
      },
      bookings: {}
    };
  }

  public async componentDidMount(): Promise<void> {
    const userInfo = AuthService.getDecodedJwtInfo();
    const accountInfo = {
      name: `${userInfo.firstname} ${userInfo.surname}`,
      email: userInfo.email,
      phone: userInfo.phone_mobile,
      roles: userInfo.attribute_names
    };

    this.setState({
      accountInfo
    });
    await this.loadAndSetSlots();
  }

  private async loadAndSetSlots(): Promise<void> {
    const bookings = await this.memberBookingApiService.getAll()

    this.setState({
      bookings: bookings.payload
    });
  }

  private onTabSelected(tabName: string): void {
    this.setState({
      tabs: this.state.tabs.map(tab => {
        tab.isCurrent = false;
        if (tab.name === tabName) {
          tab.isCurrent = true;
        }
        return tab;
      })
    });
  }

  private getCurrentTabName(): string {
    return this.state.tabs.find(({isCurrent}) => isCurrent === true).name;
  }

  private async onClickCancelBookingButton(bookingId: string): Promise<void> {
    await this.memberBookingApiService.cancelBooking(parseInt(bookingId));
    await this.loadAndSetSlots();
  }

  private getMyAccountTabContent(): JSX.Element {
    return (<div className='bg-white'>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Account Information</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">The following is the personal information which the Booking System currently holds concerning you. You can update this information on FlyTrak in the clubhouse, and changes will be pushed to here.</p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Full name</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{this.state.accountInfo.name}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Email</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{this.state.accountInfo.email}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Mobile</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{this.state.accountInfo.phone}</dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Roles</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{this.state.accountInfo.roles.join(', ')}</dd>
          </div>
        </dl>
      </div>
    </div>);
  }

  private getBookingsContent(): JSX.Element {
    return (<div className='bg-white'>
    <div className="px-4 py-5 sm:px-6">
      <h3 className="text-lg leading-6 font-medium text-gray-900">Bookings</h3>
    </div>
    <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Aircraft
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              Start
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              End
            </th>
            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {Object.keys(this.state.bookings).map((bookingId) => (
            <tr key={bookingId}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {this.state.bookings[bookingId].aircraft}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{this.state.bookings[bookingId].begins}</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{this.state.bookings[bookingId].ends}</td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              <button type="button" className="text-indigo-600 hover:text-indigo-900" onClick={() => this.onClickCancelBookingButton(bookingId)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
    );
  }

  private getContentForTab(currentTabName: string): JSX.Element {
    switch (currentTabName.toLowerCase()) {
      case 'account':
        return this.getMyAccountTabContent();
      case 'bookings':
        return this.getBookingsContent();
      case 'currency':
        return <div>{currentTabName}</div>;
      default:
        break;
    }
  }

  render() {
    return (
      <AuthorisedPageContainer pageTitle={'My details'}>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            defaultValue={this.state.tabs.find((tab) => tab.isCurrent).name}
          >
            {this.state.tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {this.state.tabs.map((tab) => (
                <button
                  key={tab.name}
                  type="button"
                  onClick={() => this.onTabSelected(tab.name)}
                  className={classNames(
                    tab.isCurrent
                      ? 'border-indigo-500 text-indigo-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                  )}
                  aria-current={tab.isCurrent ? 'page' : undefined}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
        {this.getContentForTab(this.getCurrentTabName())}
      </AuthorisedPageContainer>
    );
  }
}
