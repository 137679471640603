import jwt from 'jsonwebtoken';

interface JwtInfo {
  attribute_ids: string[];
  attribute_names: string[];
  booking_uid: string;
  email: string;
  exp: number;
  firstname: string;
  iat: number;
  is_archived: boolean;
  is_enabled: boolean;
  phone_mobile: string;
  surname: string;
}

export const AuthService = {
  isAuthenticated: () => {
    return !!localStorage.getItem('access_token');
  },

  getAuthToken: () => {
    return localStorage.getItem('access_token');
  },

  setAuthToken: (token: string) => {
    return localStorage.setItem('access_token', token);
  },

  getLoggedInUserName(): string {
    const token = localStorage.getItem('access_token');
    const decoded = jwt.decode(token);

    return `${(decoded as JwtInfo).firstname} ${(decoded as JwtInfo).surname}`;
  },

  getLoggedInUserId(): string {
    const token = localStorage.getItem('access_token');
    const decoded = jwt.decode(token);

    return (decoded as JwtInfo).booking_uid;
  },

  getDecodedJwtInfo(): JwtInfo {
    const token = localStorage.getItem('access_token');
    const decoded = jwt.decode(token);

    return (decoded as JwtInfo);
  },

  initiateOauthRedirect(): void {
    const redirectUri = window.location.origin;
    const state = new Date().getTime();
    const clientId = 'gfc-booking6-dev';
    const responseType = 'code';
    const scope = 'login';
    window.location.href = `https://auth.galwayflyingclub.org/grant/auth_code?response_type=${responseType}&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUri}`;
  }
}
