import { DateTime } from 'luxon';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom'
import IntroductoryFlightsApiService from '../api/introductory-flights/introductory-flights-api.service';
import AuthorisedPageContainer from '../components/authorised-page-container.component'

interface IntroductoryFlightPageProps extends RouteComponentProps<{}> {
  pageTitle: string;
}

interface IntroductoryFlightPageState {
}

export default class IntroductoryFlightPage extends React.Component<IntroductoryFlightPageProps, IntroductoryFlightPageState> {
  private introductoryFlightsApiService: IntroductoryFlightsApiService;

  constructor(props: IntroductoryFlightPageProps) {
    super(props);
    this.introductoryFlightsApiService = new IntroductoryFlightsApiService();
  }

  public async componentDidMount(): Promise<void> {
    const d = new Date();
    const startDateTime = DateTime.fromJSDate(d).set({hour: 8, minute: 0}).toJSDate();
    const endDateTime = DateTime.fromJSDate(d).plus({days: 60, minutes: 0}).toJSDate();
    const intros = await this.introductoryFlightsApiService.getBookings(startDateTime, endDateTime)
    console.log({intros})
  }

  render() {
    return (
      <AuthorisedPageContainer pageTitle={'Introductory Flights'}>
        <div className="pb-5 border-b border-gray-200">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Upcoming Introductory Flights</h3>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                {/* <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Start
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        End
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {Object.keys(this.state.slots).map((slotId) => (
                      <tr key={slotId}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {this.state.slots[slotId].instructorFirstname}
                          {' '}
                          {this.state.slots[slotId].instructorSurname}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{this.state.slots[slotId].availableFrom}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{this.state.slots[slotId].availableUntil}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                         <button type="button" className="text-indigo-600 hover:text-indigo-900" onClick={() => this.deleteBooking(slotId)}>
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table> */}
              </div>
            </div>
          </div>
        </div>
      </AuthorisedPageContainer>
    );
  }
}
