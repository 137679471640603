import React from 'react';
import { RouteComponentProps } from 'react-router-dom'
import InstructionApiService from '../api/instruction/instruction-api.service';
import AuthorisedPageContainer from '../components/authorised-page-container.component'
import BookInstructorSlotModalComponent from '../components/book-instructor-slot-modal.component';
import { Instruction } from '../models/instruction.model';
import { getDateTimeStringforDate } from '../services/date-time.utils';

interface InstructorTimesheetPageProps extends RouteComponentProps<{}> {
  pageTitle: string;
}

interface InstructorTimesheetPageState {
  slots: {[key: string]: Instruction};
  shouldShowCreateSlotModal: boolean;
}

export default class InstructorTimesheetPage extends React.Component<InstructorTimesheetPageProps, InstructorTimesheetPageState> {
  private instructionApiService: InstructionApiService;

  constructor(props: InstructorTimesheetPageProps) {
    super(props);
    this.instructionApiService = new InstructionApiService();

    this.state = {
      slots: {},
      shouldShowCreateSlotModal: false
    };

    this.deleteBooking = this.deleteBooking.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.loadAndSetSlots();
  }

  private async deleteBooking(slotId: string): Promise<void> {
    await this.instructionApiService.delete(slotId);
    await this.loadAndSetSlots();
  }

  private async loadAndSetSlots(): Promise<void> {
    const { payload } = await this.instructionApiService.getAll()
    this.setState({slots: payload})
  }

  private showBookingModal(): void {
    this.setState({shouldShowCreateSlotModal: true});
  }

  private async bookNewSession(bookingStart: Date, bookingEnd: Date): Promise<void> {
    // from, until
    console.log(bookingStart, bookingEnd)
    console.log(getDateTimeStringforDate(bookingStart))
    await this.instructionApiService.createOne({
      from: getDateTimeStringforDate(bookingStart),
      until: getDateTimeStringforDate(bookingEnd)
    } as any);

    await this.loadAndSetSlots();
  }

  private onModalDismissed(): void {
    this.setState({
      shouldShowCreateSlotModal: false
    });
  }

  render() {
    return (
      <AuthorisedPageContainer pageTitle={'Instructor Timesheet'}>
        <div className="py-2 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Your slots</h3>
            <p className="text-sm text-gray-500">
              Create, delete and view your instructor slots
            </p>
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" onClick={() => this.showBookingModal()}>
              New slot
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Start
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        End
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {Object.keys(this.state.slots).map((slotId) => (
                      <tr key={slotId}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {this.state.slots[slotId].instructorFirstname}
                          {' '}
                          {this.state.slots[slotId].instructorSurname}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{this.state.slots[slotId].availableFrom}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{this.state.slots[slotId].availableUntil}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                         <button type="button" className="text-indigo-600 hover:text-indigo-900" onClick={() => this.deleteBooking(slotId)}>
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {Object.keys(this.state.slots).length === 0 ? <p className='text-center'>You have no upcoming slots</p> : <></>}
              </div>
            </div>
          </div>
        </div>
        {(this.state.shouldShowCreateSlotModal) ?
          <BookInstructorSlotModalComponent onClickBookButton={(start, end) => this.bookNewSession(start, end)} onClose={() => this.onModalDismissed()} /> : <></>
        }

      </AuthorisedPageContainer>
    );
  }
}
