import React from 'react';
import { RouteComponentProps } from 'react-router-dom'
import AuthorisedPageContainer from '../components/authorised-page-container.component'

interface DutyPilotRosterPageProps extends RouteComponentProps<{}> {
  pageTitle: string;
}

interface DutyPilotRosterPageState {
}

export default class DutyPilotRosterPage extends React.Component<DutyPilotRosterPageProps, DutyPilotRosterPageState> {

  render() {
    return (
      <AuthorisedPageContainer pageTitle={'Duty Pilot Roster'}>

      </AuthorisedPageContainer>
    );
  }
}
