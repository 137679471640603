import axios from "axios";
import { BookingLibrary } from "../../models/booking.model";
import { getDateTimeStringforDate } from "../../services/date-time.utils";
import { BaseCrudApi, GenericApiResponse } from "../base-crud-api.service";

interface BookingApiResponse {
  active: BookingLibrary.Booking;
  queued: BookingLibrary.Booking;
  'slot-duration': string;
}
export default class BookingApiService extends BaseCrudApi<BookingApiResponse, any> {
  constructor() {
    super('booking');
  }

  public async getBookings(startDate: Date, endDate: Date): Promise<GenericApiResponse<BookingApiResponse>> {
    const startDateTime = getDateTimeStringforDate(startDate);
    const endDateTime = getDateTimeStringforDate(endDate);
    const defaultHeaders = await this.getDefaultHeaders();
    let url = `${this.apiUrl}/${startDateTime}/${endDateTime}`;

    return axios.get(url, {
        ...defaultHeaders,
      })
      .then(response => response.data);
  }
}
