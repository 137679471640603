import axios from 'axios';
import { AuthService } from '../services/auth.service';

export default function createAuthInterceptor() {
  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    // token expired or is not present
    if (error.response?.status === 401) {
      AuthService.initiateOauthRedirect()
    }
    return error;
  });
}
