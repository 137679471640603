import axios from "axios";
import { AircraftStatus } from "../../models/aircraft-status.model";
import { BaseCrudApi, GenericApiResponse } from "../base-crud-api.service";

export default class AircraftStatusApiService extends BaseCrudApi<AircraftStatus, any> {
  constructor() {
    super('aircraft');
  }

  public async getAircraftStatus(): Promise<GenericApiResponse<AircraftStatus>> {
    const defaultHeaders = await this.getDefaultHeaders();
    let url = `${this.apiUrl}/status`;

    const data = await axios.get(url, {
        ...defaultHeaders,
      })
      .then(response => response.data);

    return data;
  }

}
