import axios from 'axios';
import { AuthService } from '../services/auth.service';

interface DefaultHeaders {
  headers: AuthHeader
}

interface AuthHeader {
  ['Authorization']: string;
}

export interface GenericApiResponse<M> {
  payload: M;
  errors: string[];
}

export abstract class BaseCrudApi<T, U> {
  private readonly apiVersion = 'v3';
  private readonly baseApiUrl = `https://bookingapi.galwayflyingclub.org/api/${this.apiVersion}/`;
  constructor(
    // for https://bookingapi.galwayflyingclub.org/api/v3/booking/202106200800/202106201900
    // the apiResourcePath would be 'booking'
    private apiResourcePath: string
  ) {}

  protected get apiUrl(): string {
    return `${this.baseApiUrl}${this.apiResourcePath}`;
  }

  private async getToken(): Promise<string> {
    return AuthService.getAuthToken();
  }

  private async getAuthHeader(): Promise<{Authorization: string}> {
    const token = await this.getToken();

    return {
      Authorization: `Bearer ${token}`
    };
  }

  protected async getDefaultHeaders(): Promise<DefaultHeaders> {
    const authHeader = await this.getAuthHeader();
    return {
      headers: { ...authHeader }
    };
  }

  public async createOne(body: Partial<T>): Promise<GenericApiResponse<T>> {
    const defaultHeaders = await this.getDefaultHeaders();
    const data = await axios.post(this.apiUrl, body, {
        ...defaultHeaders,
      })
      .then(response => response.data);

    return data;
  }

  public async getAll(queryParams?: U, additionalPathParameters?: string): Promise<GenericApiResponse<{[key: string]: T}>> {
    const defaultHeaders = await this.getDefaultHeaders();
    let url = this.apiUrl

    if (!!additionalPathParameters) {  // true if an override is provided
      url = `${this.apiUrl}/${additionalPathParameters}`;
    }

    const data = await axios.get(url, {
        ...defaultHeaders,
        params: queryParams
      })
      .then(response => response.data);

    return data;
  }

  public async getOneById(id: string, queryParams?: U): Promise<GenericApiResponse<T>> {
    const defaultHeaders = await this.getDefaultHeaders();
    const data = await axios.get(`${this.apiUrl}/${id}`, {
        ...defaultHeaders,
        params: queryParams
      })
      .then(response => response.data);

    return data;
  }

  public async update(id: string, body: Partial<T>): Promise<GenericApiResponse<T>> {
    const defaultHeaders = await this.getDefaultHeaders();
    const data = await axios.put(`${this.apiUrl}/${id}`, body, {
        ...defaultHeaders,
      })
      .then(response => response.data);

    return data;
  }

  public async delete(id: string): Promise<void> {
    const defaultHeaders = await this.getDefaultHeaders();
    const data = await axios.delete(`${this.apiUrl}/${id}`, {
        ...defaultHeaders,
      })
      .then(response => response.data);

    return data;
  }
}
