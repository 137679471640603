import React from 'react';
import { BookingLibrary } from '../models/booking.model';
import { getPaddedHourFromDate, getPaddedMinuteFromDate } from '../services/date-time.utils';
import { DateTime } from 'luxon';
import { buildSlotsFromBookings } from '../services/booking.utils';
import BookingModalComponent from './booking-modal.component';
import { AuthService } from '../services/auth.service';
import CancelBookingModalComponent from './cancel-booking-confirmation-modal.component';
import { ModalType } from '../constants/modal-type.enum';

interface BookingListComponentProps {
  aircraftRegistration: string;
  vfrStartTime: string;
  vfrEndTime: string;
  activeBookings: BookingLibrary.AircraftBooking;
  queuedBookings: BookingLibrary.AircraftBooking;
  slotSizeInMinutes: number;
  firstSlotTime: string;
  lastSlotTime: string;
  bookingDate: Date;
  onClickBookButton: (aircraftRegistration: string, slotBegin: Date, slotEnd: Date) => Promise<void>;
  onClickCancelButton: (bookingId: number) => Promise<void>;
}

interface BookingListComponentState {
  slots: BookingLibrary.AircraftBookingSlot[];
  queuedSlots: BookingLibrary.AircraftBookingSlot[];
  isOpen: boolean;
  selectedModalSlot: BookingLibrary.AircraftBookingSlot;
  modalType: ModalType;
}

export default class BookingListComponent extends React.Component<BookingListComponentProps, BookingListComponentState> {

  constructor(props: BookingListComponentProps) {
    super(props)
    this.state = {
      slots: [],
      queuedSlots: [],
      isOpen: true,
      selectedModalSlot: null,
      modalType: null
    }
    this.onClickBookButton = this.onClickBookButton.bind(this);
    this.onClickCancelButton = this.onClickCancelButton.bind(this);
  }

  private getActionButtonForGivenSlot(slot: BookingLibrary.AircraftBookingSlot, queuedSlot: BookingLibrary.AircraftBookingSlot): JSX.Element {
    const loggedInUserId = AuthService.getLoggedInUserId();
    if (slot.ownerUid && slot.ownerUid === loggedInUserId) {
      return <button type="button" className="text-indigo-600 hover:text-indigo-900" onClick={() => this.setState({ selectedModalSlot: slot, modalType: ModalType.Cancel })}>Cancel</button>
    } else if (slot.ownerUid && queuedSlot.ownerUid && queuedSlot.ownerUid === loggedInUserId) {
      return <button type="button" className="text-indigo-600 hover:text-indigo-900" onClick={() => this.setState({ selectedModalSlot: queuedSlot, modalType: ModalType.Cancel })}>Cancel</button>
    } else if (slot.ownerUid && !queuedSlot.ownerUid) {
      return <button type="button" className="text-indigo-600 hover:text-indigo-900" onClick={() => this.setState({ selectedModalSlot: slot, modalType: ModalType.Book })}>Queue</button>
    } else if (!queuedSlot.ownerUid) {
      return <button type="button" className="text-indigo-600 hover:text-indigo-900" onClick={() => this.setState({ selectedModalSlot: slot, modalType: ModalType.Book })}>Book</button>;
    }

    return <></>;
  }

  static getDerivedStateFromProps(props: BookingListComponentProps, state: any): any {
    return {
      slots: buildSlotsFromBookings(
        props.aircraftRegistration,
        props.activeBookings,
        props.firstSlotTime,
        props.lastSlotTime,
        props.bookingDate
      ),
      queuedSlots: buildSlotsFromBookings(
        props.aircraftRegistration,
        props.queuedBookings,
        props.firstSlotTime,
        props.lastSlotTime,
        props.bookingDate
      ),
    }
  }

  private async onClickBookButton(aircraftRegistration: string, slotBegin: Date, slotEnd: Date): Promise<void> {
    return this.props.onClickBookButton(aircraftRegistration, slotBegin, slotEnd);
  }

  private async onClickCancelButton(bookingId: number): Promise<void> {
    return this.props.onClickCancelButton(bookingId);
  }


  render(): JSX.Element {
    return (<div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">slot time</span>
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {this.props.aircraftRegistration}
                    </th>
                    <th scope="col" className="relative"></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {this.state.slots.map((slot, i) => {
                    const queuedSlot = this.state.queuedSlots[i];
                    return (
                      <tr key={`${slot.begins}-${slot.id}`}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {getPaddedHourFromDate(DateTime.fromSQL(slot.begins))}:{getPaddedMinuteFromDate(DateTime.fromSQL(slot.begins))}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <span className="font-semibold">{slot.ownerFirstname}{' '}{slot.ownerSurname}</span>
                          {queuedSlot.id ?
                            <span><br />Queued: {queuedSlot.ownerFirstname}{' '}{queuedSlot.ownerSurname}</span> : <></>}
                        </td>
                        <td className="px-6 py-4">
                          {this.getActionButtonForGivenSlot(slot, queuedSlot)}
                        </td>
                      </tr>
                    );
                  })
                }
                </tbody>
              </table>
              {(this.state.selectedModalSlot && this.state.modalType === ModalType.Book) ?
                <BookingModalComponent slot={this.state.selectedModalSlot} onClickBookButton={this.onClickBookButton} onClose={() => this.setState({selectedModalSlot: null, modalType: null })} /> : <></>
              }
              {(this.state.selectedModalSlot && this.state.modalType === ModalType.Cancel) ?
                <CancelBookingModalComponent slot={this.state.selectedModalSlot} onClickCancelButton={this.onClickCancelButton} onClose={() => this.setState({selectedModalSlot: null, modalType: null })} /> : <></>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}
