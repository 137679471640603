import React from 'react';
import SunCalc from 'suncalc';
import { RouteComponentProps } from 'react-router-dom'
import AuthorisedPageContainer from '../components/authorised-page-container.component'
import BookingListComponent from '../components/booking-list.component'
import BookingApiService from '../api/booking/booking-api.service'
import { BookingLibrary } from '../models/booking.model'
import { DateTime } from 'luxon';
import DatePicker from 'react-date-picker'
import AircraftStatusApiService from '../api/aircraft-status/aircraft-status-api.service';
import AuthApiService from '../api/auth/auth-api.service';
import MemberBookingApiService from '../api/booking/member-booking-api.service';
import { AircraftStatus } from '../models/aircraft-status.model';
import InstructorApiService from '../api/instructor/instructor-api.service';
import { Instruction } from '../models/instruction.model';
import { getTimeRangeFromDates } from '../services/date-time.utils';
interface BookingPageProps extends RouteComponentProps<{}> {
  pageTitle: string;
}

interface BookingPageState {
  activeBookings: BookingLibrary.Booking;
  queuedBookings: BookingLibrary.Booking;
  bookingDate: Date;
  aircraftStatus: AircraftStatus;
  instructorAvailability: Instruction[];
  daylightTimes: {
    sunset: Date;
    sunrise: Date;
    vfrStart: Date;
    vfrEnd: Date;
  }
}

export default class BookingPage extends React.Component<BookingPageProps, BookingPageState> {
  private bookingService: BookingApiService;
  private instructorApiService: InstructorApiService;
  private memberBookingApiService: MemberBookingApiService;
  private aircraftStatusApiService: AircraftStatusApiService;
  private authApiService: AuthApiService;

  constructor(props: BookingPageProps) {
    super(props);
    this.bookingService = new BookingApiService();
    this.instructorApiService = new InstructorApiService();
    this.memberBookingApiService = new MemberBookingApiService();
    this.aircraftStatusApiService = new AircraftStatusApiService();
    this.authApiService = new AuthApiService();

    this.state = {
      activeBookings: {},
      queuedBookings: {},
      bookingDate: new Date(),
      aircraftStatus: {},
      instructorAvailability: [],
      daylightTimes: {
        sunrise: null,
        sunset: null,
        vfrStart: null,
        vfrEnd: null
      }
    };

    this.onDateChange = this.onDateChange.bind(this);
    this.onClickBookButton = this.onClickBookButton.bind(this);
    this.onClickCancelButton = this.onClickCancelButton.bind(this);
  }

  async componentDidMount(): Promise<void> {
    await this.loadBookingsForDate(this.state.bookingDate);
    const aircraftStatusResponse = await this.aircraftStatusApiService.getAircraftStatus();
    this.setState({aircraftStatus: aircraftStatusResponse.payload});
    await this.loadInstructorAvailabilityForDate(this.state.bookingDate);
    this.getAndSetDaylightAndVFRTimes();
  }

  private getAndSetDaylightAndVFRTimes(): void {
    const d = this.state.bookingDate;
    const eicmLatitude = 53.300278;
    const eicmLongitude = -8.941111;
    const aprilMonthNumber = 3;
    const octoberMonthNumber = 9;
    const isSummerVFRHours = this.state.bookingDate.getMonth() >= aprilMonthNumber && this.state.bookingDate.getMonth() < octoberMonthNumber; // 1 April to 30 Sept

    const { sunset, sunrise } = SunCalc.getTimes(this.state.bookingDate, eicmLatitude, eicmLongitude);
    let vfrStart = DateTime.fromJSDate(sunrise);
    let vfrEnd = DateTime.fromJSDate(sunset);
    if (isSummerVFRHours) {
      vfrStart = vfrStart.minus({minutes: 30});
      vfrEnd = vfrEnd.plus({minutes: 30});
    }

    this.setState({
      daylightTimes: {
        sunrise,
        sunset,
        vfrStart: vfrStart.toJSDate(),
        vfrEnd: vfrEnd.toJSDate()
      }
    })
  }

  private onDateChange(newDate: Date): void {
    this.setState({
      bookingDate: newDate
    }, () => {
      this.loadBookingsForDate(newDate);
      this.loadInstructorAvailabilityForDate(newDate);
      this.getAndSetDaylightAndVFRTimes();
    });
  }

  private getSoonestMaintainanceTypeForAircraft(aircraftStatus: AircraftStatus, aircraftReg: string): string {
    const dateOfAnnual = DateTime.fromFormat(aircraftStatus[aircraftReg].dateAnnual, 'yyyy-MM-dd')
    const dateOfCOfA = DateTime.fromFormat(aircraftStatus[aircraftReg].dateArc, 'yyyy-MM-dd')

    if (dateOfAnnual.startOf('day') <= dateOfCOfA.startOf('day')) {
      return 'Annual';
    }

    return 'C. of A.';
  }

  private async loadBookingsForDate(d: Date): Promise<void> {
    const startDateTime = DateTime.fromJSDate(d).set({hour: 8, minute: 0}).toJSDate();
    const endDateTime = DateTime.fromJSDate(d).set({hour: 22, minute: 0}).toJSDate();

    const bookings = await this.bookingService.getBookings(startDateTime, endDateTime);

    this.setState({
      activeBookings: bookings.payload.active,
      queuedBookings: bookings.payload.queued
    });
  }

  private async loadInstructorAvailabilityForDate(d: Date): Promise<void> {
    const startDateTime = DateTime.fromJSDate(d).set({hour: 8, minute: 0}).toJSDate();
    const endDateTime = DateTime.fromJSDate(d).set({hour: 22, minute: 0}).toJSDate();

    const instructorAvailabilityResponse = await this.instructorApiService.getInstructorBookings(startDateTime, endDateTime);

    this.setState({instructorAvailability: instructorAvailabilityResponse.payload});
  }

  private async onClickBookButton(aircraftRegistration: string, begin: Date, end: Date): Promise<void> {
    await this.memberBookingApiService.createBooking(aircraftRegistration, begin, end)
  }

  private async onClickCancelButton(bookingId: number): Promise<void> {
    await this.memberBookingApiService.cancelBooking(bookingId)
  }

  private convertInstructionSlotToDateRangeString(instructionSlot: Instruction): string {
    const { availableFrom, availableUntil } = instructionSlot;
    return getTimeRangeFromDates(DateTime.fromSQL(availableFrom), DateTime.fromSQL(availableUntil));
  }

  render() {
    console.log(this.state.daylightTimes.sunrise)
    return (
      <AuthorisedPageContainer pageTitle={'Booking'}>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">Daylight &amp; VFR</dt>
            <dd className="mt-1 text-gray-900">
              <div>
                <span className='font-semibold'>Daylight</span>
                {' '}
                {this.state.daylightTimes.sunrise ? <span>{DateTime.fromJSDate(this.state.daylightTimes.sunrise).toLocaleString(DateTime.TIME_SIMPLE)} - {DateTime.fromJSDate(this.state.daylightTimes.sunset).toLocaleString(DateTime.TIME_SIMPLE)}</span> : <></>}
              </div>
              <div>
                <span className='font-semibold'>VFR</span>
                {' '}
                {this.state.daylightTimes.vfrStart ? <span>{DateTime.fromJSDate(this.state.daylightTimes.vfrStart).toLocaleString(DateTime.TIME_SIMPLE)} - {DateTime.fromJSDate(this.state.daylightTimes.vfrEnd).toLocaleString(DateTime.TIME_SIMPLE)}</span> : <></>}
              </div>
            </dd>
          </div>

          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">Aircraft</dt>
            <dd className="mt-1 text-gray-900">
              {Object.keys(this.state.aircraftStatus).filter((reg) => reg !== 'EI-BIB').map(key => {
                return <div key={key}>
                    <span className='font-semibold'>{key}</span>
                    {' '}
                    <span>{Math.round((parseFloat(this.state.aircraftStatus[key].tachCheck) - parseFloat(this.state.aircraftStatus[key].tachCurrent)) * 10) / 10} hours; </span>
                    <span>{this.getSoonestMaintainanceTypeForAircraft(this.state.aircraftStatus, key)} on </span>
                    <span>{this.getSoonestMaintainanceTypeForAircraft(this.state.aircraftStatus, key) === 'Annual' ? this.state.aircraftStatus[key].dateAnnual : this.state.aircraftStatus[key].dateArc}</span>
                  </div>
              })}
            </dd>
          </div>

          <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">Instructors</dt>
            {this.state.instructorAvailability.length > 0 ? this.state.instructorAvailability.map((instructorSlot, i) => {
              return <dd key={`instruction-${i}`} className="mt-1 font-semibold text-gray-900">{instructorSlot.instructorFirstname}{' '}{instructorSlot.instructorSurname}{'\t'}{this.convertInstructionSlotToDateRangeString(instructorSlot)}</dd>
            }) : <span>None.</span>}
          </div>
        </dl>

        <div className="w-full">
          <DatePicker
            className="appearance-none block  bg-white rounded-md shadow-sm sm:text-sm mt-5 mb-2"
            clearIcon={null}
            value={this.state.bookingDate}
            onChange={this.onDateChange}
          />
        </div>
        <div className="w-full flex flex-col md:flex-row ">
          {Object.keys(this.state.activeBookings).filter((reg: string) => reg !== 'EI-BIB').map((aircraftRegistration: string) => {
            return (<div className="flex-grow mr- md:mr-1 mt-2" key={aircraftRegistration}>
              <BookingListComponent
                aircraftRegistration={aircraftRegistration}
                vfrStartTime={'vfrStartTime'}
                vfrEndTime={'vfrEndTime'}
                activeBookings={this.state.activeBookings[aircraftRegistration]}
                queuedBookings={this.state.queuedBookings[aircraftRegistration]}
                slotSizeInMinutes={60}
                firstSlotTime={'08:00'}
                lastSlotTime={'22:00'}
                bookingDate={this.state.bookingDate}
                onClickBookButton={this.onClickBookButton}
                onClickCancelButton={this.onClickCancelButton}
              ></BookingListComponent>
            </div>);
          })}
        </div>
      </AuthorisedPageContainer>
    );
  }
}
