import axios from "axios";
import { getDateTimeStringforDate } from "../../services/date-time.utils";
import { BaseCrudApi, GenericApiResponse } from "../base-crud-api.service";

export default class MemberBookingApiService extends BaseCrudApi<any, any> {
  constructor() {
    super('member/booking');
  }

  public async createBooking(aircraftRegistration: string, startDate: Date, endDate: Date): Promise<GenericApiResponse<any>> {
    const startDateTime = getDateTimeStringforDate(startDate);
    const endDateTime = getDateTimeStringforDate(endDate);
    const defaultHeaders = await this.getDefaultHeaders();

    const data = await axios.post(this.apiUrl,
      {
        aircraft: aircraftRegistration,
        from: startDateTime,
        until: endDateTime
      },
      {
        ...defaultHeaders,
      })
      .then(response => response.data);

    return data;
  }

  public async cancelBooking(bookingId: number): Promise<GenericApiResponse<any>> {
    const defaultHeaders = await this.getDefaultHeaders();

    const data = await axios.delete(`${this.apiUrl}/${bookingId}`,
      {
        ...defaultHeaders,
      })
      .then(response => response.data);

    return data;
  }

}
