import { DateTime } from 'luxon';
import { BookingLibrary } from "../models/booking.model";

export function buildSlotsFromBookings(aircraftRegistration: string, bookings: BookingLibrary.AircraftBooking, firstSlot: string, lastSlot: string, bookingDate: Date): BookingLibrary.AircraftBookingSlot[] {

  const slots = getEmptySlots(aircraftRegistration, firstSlot, lastSlot, bookingDate);
  const bookingsAsArr = Object.keys(bookings).map((slotId) => bookings[slotId]);
  const slotsWithBookings = slots.map(slot => {
    const booking = findBookingsForSlot(bookingsAsArr, slot);
    if (!!booking) {
      return Object.assign({}, booking, {begins: slot.begins, ends: slot.ends});
    }

    return slot;
  });

  return slotsWithBookings
}

function getEmptySlots(aircraftRegistration: string, firstSlot: string, lastSlot: string, bookingDate: Date): BookingLibrary.AircraftBookingSlot[] {
  const slots: BookingLibrary.AircraftBookingSlot[] = [];
  const [firstSlotHour, firstSlotMinute]: string[] = firstSlot.split(':');
  let currentSlotDate = DateTime.fromJSDate(bookingDate).set({
    hour: parseInt(firstSlotHour),
    minute: parseInt(firstSlotMinute),
    second: 0,
    millisecond: 0
  });
  const [lastSlotHour, lastSlotMinute]: string[] = lastSlot.split(':');
  const lastSlotDate = DateTime.fromJSDate(bookingDate).set({
    hour: parseInt(lastSlotHour),
    minute: parseInt(lastSlotMinute),
    second: 0,
    millisecond: 0
  });

  // create the empty slots
  while (lastSlotDate >= currentSlotDate) {
    const slotEnd = currentSlotDate.plus({hours: 1});
    slots.push({
      aircraft: aircraftRegistration,
      begins: currentSlotDate.toFormat('yyyy-MM-dd HH:mm'), //"2021-09-12 10:00",
      ends: slotEnd.toFormat('yyyy-MM-dd HH:mm'),
      id: null,
      ownerFirstname: null,
      ownerSurname: null,
      ownerUid: null,
      typeCode: null,
      typeName: null
    })
    currentSlotDate = currentSlotDate.plus({hours: 1});
  }

  return slots;
}

function findBookingsForSlot(bookings: BookingLibrary.AircraftBookingSlot[], slot: BookingLibrary.AircraftBookingSlot): BookingLibrary.AircraftBookingSlot {
  return bookings.find((bk) => {
    const slotBegin = DateTime.fromSQL(slot.begins);
    const slotEnd = DateTime.fromSQL(slot.ends);
    const bookingBegin = DateTime.fromSQL(bk.begins);
    const bookingEnd = DateTime.fromSQL(bk.ends);

    return slotBegin.toMillis() >= bookingBegin.toMillis() &&
      bookingEnd.toMillis() >= slotEnd.toMillis();
  });
}
