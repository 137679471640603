import axios from "axios";
import { Instruction } from "../../models/instruction.model";
import { getDateTimeStringforDate } from "../../services/date-time.utils";
import { BaseCrudApi, GenericApiResponse } from "../base-crud-api.service";

export default class InstructorApiService extends BaseCrudApi<Instruction, any> {
  constructor() {
    super('instructor');
  }

  public async getInstructorBookings(startDate: Date, endDate: Date): Promise<GenericApiResponse<Instruction[]>> {
    const startDateTime = getDateTimeStringforDate(startDate);
    const endDateTime = getDateTimeStringforDate(endDate);
    const defaultHeaders = await this.getDefaultHeaders();
    let url = `${this.apiUrl}/${startDateTime}/${endDateTime}`;

    const data = await axios.get(url, {
        ...defaultHeaders,
      })
      .then(response => response.data);

    return data;
  }
}
