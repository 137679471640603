// technically this isn't a service, rather a collection of methods

import { DateTime } from "luxon";

export function getDateTimeStringforDate(date: Date): string {
  return DateTime.fromJSDate(date).toFormat('yyyyLLddHHmm');
}

// pads a number with a leading 0 if less than 10
// This is necessary as the date object will return 9 for what should be "09"
// maybe this should be more generic (i.e. it's not just for date numbers)
export function padDateOrTimeValueIfNecessary(val: number): string {
  if (val < 10) {
    return `0${val}`;
  }

  return `${val}`;
}

export function getPaddedHourFromDate(date: DateTime): string {
  return padDateOrTimeValueIfNecessary(date.hour);
}

export function getPaddedMinuteFromDate(date: DateTime): string {
  return padDateOrTimeValueIfNecessary(date.minute);
}

export function getTimeRangeFromDates(start: DateTime, end: DateTime): string {
  return `${start.toLocaleString(DateTime.TIME_24_SIMPLE)} - ${end.toLocaleString(DateTime.TIME_24_SIMPLE)}`;
}
