import { Dialog, Transition } from '@headlessui/react'
import { DateTime } from 'luxon';
import React, { Fragment } from 'react';
import { BookingLibrary } from '../models/booking.model';

interface CancelBookingModalComponentProps {
  slot: BookingLibrary.AircraftBookingSlot;
  onClose: () => void;
  onClickCancelButton: (bookingId: number) => Promise<void>;
}

interface CancelBookingModalComponentState {
}

export default class CancelBookingModalComponent extends React.Component<CancelBookingModalComponentProps, CancelBookingModalComponentState> {

  render(): JSX.Element {
    return (<Transition appear show={true} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => this.props.onClose() }
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-40"
            leave="ease-in duration-200"
            leaveFrom="opacity-40"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Are you sure you want to cancel your booking?
              </Dialog.Title>
              <div className="mt-4">
              <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={() =>
                    this.props.onClickCancelButton(
                      this.props.slot.id
                    )
                      .then(this.props.onClose)
                  }
                >
                  Yes, cancel booking
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-900 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                  onClick={() =>
                    this.props.onClose()
                  }
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>);
  }
}
