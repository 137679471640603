import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import createAuthInterceptor from "./api/interceptors";
import BookingPage from './pages/booking-timesheet.page';
import DutyPilotRosterPage from './pages/duty-pilot-roster.page';
import InstructorTimesheetPage from "./pages/instructor-timesheet.page";
import IntroductoryFlightPage from "./pages/introductory-flights.page";
import LoginPage from './pages/login.page';
import MyAccountPage from './pages/my-account.page';
import OauthRedirectPage from "./pages/oauth-redirect.page";
import { AuthService } from './services/auth.service';

// handle API errors
createAuthInterceptor();

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={OauthRedirectPage} />
          <PrivateRoute path="/booking-timesheet" component={BookingPage}></PrivateRoute>
          <PrivateRoute path="/duty-pilot" component={DutyPilotRosterPage}></PrivateRoute>
          <PrivateRoute path="/instructor-timesheet" component={InstructorTimesheetPage}></PrivateRoute>
          <PrivateRoute path="/introductory-flights" component={IntroductoryFlightPage}></PrivateRoute>
          <PrivateRoute path="/account" component={MyAccountPage}></PrivateRoute>
          <Route path="/login" component={LoginPage}></Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;


function PrivateRoute ({
  component: Component,
  ...routeProps
}: any) {
  return (
    <Route
      {...routeProps}
      render={(props) => AuthService.isAuthenticated()
        ? <Component {...props} />
        : <Redirect to={{pathname: '/', state: {from: props.location}}} />}
    />
  )
}
