import React from 'react';
import { RouteComponentProps } from 'react-router-dom'
import qs from 'querystringify';
import AuthApiService from '../api/auth/auth-api.service';
import { AuthService } from '../services/auth.service';

interface OauthRedirectPageProps extends RouteComponentProps<{}> {
}

interface OauthRedirectPageState {
}

export default class OauthRedirectPage extends React.Component<OauthRedirectPageProps, OauthRedirectPageState> {
  private authApiService: AuthApiService;

  constructor(props: OauthRedirectPageProps) {
    super(props);
    this.authApiService = new AuthApiService();
  }

  async componentDidMount(): Promise<void> {
    const hasValidToken = AuthService.isAuthenticated();
    console.log({hasValidToken });
    console.log(JSON.stringify(Object.assign({}, {d: document.location.href}), null, 4))
    if (document.location.href.includes('code') && document.location.href.includes('state')) {
      const parsedQuery = qs.parse(document.location.href.split('?')[1]) as {
        code: string, state: string
      };
      // TODO: handle errors here
      const response: any = await this.authApiService.getTokenFromCodeAndState(parsedQuery.code, parsedQuery.state);
      console.log(JSON.stringify(response.data.payload, null, 2))
      AuthService.setAuthToken(response.data.payload.access_token);

      this.props.history.replace('/booking-timesheet');
    } else if (hasValidToken) {
      this.props.history.push('/booking-timesheet');
    } else {
      AuthService.initiateOauthRedirect();
    }
  }

  render() {
    return (<div></div>);
  }
}
